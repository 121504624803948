<template>
  <VMenu
    v-if="state !== 'processed'"
    close-on-content-click
    content-class="br-8"
    :disabled="!state.length"
    nudge-left="150"
    nudge-top="10"
    offset-y
    open-on-hover
  >
    <template v-slot:activator="{ on, attrs }">
      <VBtn
        v-bind="attrs"
        icon
        v-on="on"
      >
        <VIcon v-text="'mdi-dots-horizontal'" />
      </VBtn>
    </template>
    <VList
      class="py-0 pl-1"
      flat
      min-width="210"
      nav
    >
      <VListItem @click="$emit('click', actionParams.emit)">
        <span :style="`color: ${actionParams.color}`">
          {{ actionParams.text }}
        </span>
      </VListItem>
    </VList>
  </VMenu>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  computed: {
    actionParams() {
      const params = {
        new: {
          text: 'Принять в обработку',
          color: '#EE6002',
          emit: 'assign',
        },
        in_processing: {
          text: 'Обработан',
          color: '#46C01B',
          emit: 'processed',
        },
      };
      return params[this.state];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
